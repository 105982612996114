.content {
    padding: 32px 0;
}

.checkbox_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.button {
    margin-top: 32px !important;
}

.polices {
    line-height: 24px;
    font-weight: 500;
}

.polices a:not(.link_primary) {
    color: inherit;
}

.link_primary, .link_primary span, .link_primary a {
    color: var(--color-text-main) !important;
    text-decoration: none !important;
    border-bottom: 0;
}

.allert {
    margin-bottom: 0 !important;
}

.input {
    width: inherit !important;
}
