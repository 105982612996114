.logo {
    display: flex;
    gap: 8px;
    align-items: center;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 32px;
    text-decoration: none;
    position: absolute;
    z-index: 999;
    top: 40px;
    color: var(--color-text-primary);
}

@media screen and (max-width: 998px) {
    .logo {
        top: 20px;
    }
}
