.input {
    background-color: transparent !important;
    border: none  !important;
    box-sizing: border-box !important;
    color: var(--input-color)  !important;
    display: block  !important;
    font-family: var(--font-family);
    font-size: 16px !important;
    font-weight: 400  !important;
    min-height: var(--form-control-m-min-height);
    line-height: 20px  !important;
    margin: 0  !important;
    outline: none;
    position: relative;
    text-overflow: ellipsis;
    width: 100%  !important;
}

.container {
    backdrop-filter: var(--form-control-bg-backdrop-filter);
    background-color: var(--form-control-bg-color);
    border-radius: var(--form-control-border-radius);
    outline: none;
    position: relative;
    transform: translateZ(0);
    border-bottom: 1px solid #36363a;
    z-index: 100;
}

.dropdown {
    background-color: transparent !important;
    border: 0 !important;
    padding: 6px !important;
    transform: scale(1.1);
    width: 48px !important;
}