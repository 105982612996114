@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');

body,
html {
    margin: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-bg-primary);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}

.brandBg {
    background: linear-gradient(120deg, #1189fa 0%, #b65ef7 100%);
    border: 0 !important;
    color: #fff !important;
    box-sizing: border-box !important;
    transition: all 0.2s !important;
    background-size: 100% 100%;  
}

.brandBg:hover {
    background-size: 150% 100%;  

}
